<template>
  <div class="flex flex-col min-h-screen justify-between">
    <TNav />
    <TContactChips />
    <div>
      <slot></slot>
    </div>
    <TFooter />
  </div>
</template>
