<script setup lang="ts"></script>

<template>
  <div class="sticky pointer-events-none top-0 w-full z-50">
    <div class="flex absolute p-4 gap-2 flex-wrap w-full mx-auto justify-center md:justify-end *:pointer-events-auto ">
      <UButton
        class="text-primary-950"
        :ui="{ rounded: 'rounded-xl' }"
      
      >
        <template #leading>
          <UIcon
            name="i-material-symbols:call-outline-rounded"
            class="w-5 h-5"
          />
        </template>
        500 086 533
      </UButton>

      <UButton
        class="text-primary-950"
    
        :ui="{ rounded: 'rounded-xl' }"
      >
        <template #leading>
          <UIcon
            name="i-material-symbols:mail-outline-rounded"
            class="w-5 h-5"
          /> </template
        >biuro@team.turek.pl</UButton
      >
    </div>
  </div>
</template>

<style scoped></style>
