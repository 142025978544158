<script setup lang="ts">
const { locale, setLocale, t } = useI18n();

const entries = [
  { name: t("menu.home"), path: "/" },
  { name: t("menu.internet"), path: "/internet" },
  { name: t("menu.tv"), path: "/telewizja" },
  { name: t("menu.forCom"), path: "/internet-dla-firm" },
  { name: t("menu.contact"), path: "/kontakt" },
];
const { currentRoute } = useRouter();
const mobileMenuHidden = ref<boolean>(true);
</script>

<template>
  <nav
    class="bg-primary-950 z-30 flex justify-between items-center w-full bg-primary px-4 md:px-16 lg:px-24 py-4 md:relative subtle-gradient"
  >
    <nuxtLink to="/">
      <img src="/img/team_logo_x64.png" alt="" class="h-8" />
    </nuxtLink>

    <div
      class="md:flex px-1 py-1 gap-9 *:text-on-primary *:text-base absolute hidden md:relative"
    >
      <div v-for="{ name, path } in entries">
        <UButton variant="link" :to="path" external :padded="false" prefetch>
          <span
            class="opacity-50 text-primary-50 text-base"
            :class="{ '!opacity-100': currentRoute.path == path }"
            >{{ name }}
          </span></UButton
        >
      </div>
    </div>
    <div>
      <a href="/login" class="hidden md:block">
        <UButton
          variant="link"
          class="text-primary-50"
          icon="material-symbols:account-circle"
        >
          iBOK
        </UButton>
      </a>
      <TMobileNav :links="entries" class="md:hidden block" />
    </div>
  </nav>
</template>
