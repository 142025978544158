<script setup lang="ts">
defineProps<{ links: [{ name: string; path: string }] }>();
const menuOpened = ref(false);
</script>

<template>
  <div class="relative">
    <UButton
      :icon="
        !menuOpened
          ? 'i-material-symbols-menu-rounded'
          : 'i-material-symbols-close-rounded'
      "
      variant="link"
      size="xl"
      :padded="false"
      @click="menuOpened = true"
    />
    <USlideover class="" v-model="menuOpened">
      <div class="p-4 flex-1 bg-gray-950">
        <p class="text-gray-400 text-sm bottom-2 absolute w-full text-center">
          2024 ©
        </p>
        <UButton
          variant="link"
          size="xl"
          icon="i-material-symbols-close-rounded"
          class="flex sm:hidden absolute end-5 top-5 z-10"
          square
          :padded="false"
          @click="menuOpened = false"
        />
        <div class="h-full flex flex-col justify-center gap-4 items-center mt">
          <div class="mb-4 text-center text-primary-50">
            <img class="h-9 mx-auto" src="/img/team_logo_menu.png" />
            <p class="text-sm font-bold">{{ $t("generic.turek") }}</p>
          </div>
          <div class="div text-lg" v-for="entry in links">
            <nuxtLink
              :to="entry.path"
              external
              class="text-primary-50 font-semibold"
            >
              {{ entry.name }}
            </nuxtLink>
          </div>
          <UButton
            icon="material-symbols:account-circle"
            u
            to="/ibok"
            class="mt-4"
            size="lg"
            external
          >
            iBOK
          </UButton>
        </div>
      </div>
    </USlideover>
    <!-- <div
      class="fixed top-16 right-0 bg-gray-950 z-[+100] py-10 px-20 flex flex-col gap-4 items-center"
    >
      <div class="div text-lg gl" v-for="entry in links">
        <nuxtLink
          :to="entry.path"
          external
          class="text-primary-50 font-semibold"
        >
          {{ entry.name }}
        </nuxtLink>
      </div>
   
    </div> -->
  </div>
</template>

<style scoped></style>
