<script setup lang="ts">
import { type TCompany } from "~/types/Company";
const companyData = useState<TCompany>("company");

await callOnce("company", async () => {
  companyData.value = await $fetch<TCompany>("/api/companyData");
});

const displayAddress = computed(() =>
  addressToDisplay(companyData.value.address)
);
</script>

<template>
  <div
    class="grad w-full mt-8 py-9 bg-gray-950 subtle-gradient text-primary-50"
  >
    <UContainer class="flex flex-wrap items-center justify-between gap-4">
      <img loading="lazy" class="h-12" src="/img/team_logo_x64.png" alt="" />
      <div>
        <p>Kontakt z biurem obsługi klienta</p>
        <p class="font-semibold text-sm">
          <nuxtLink :to="telToHref(companyData.phone.main)">
            {{ telToDisplay(companyData.phone.main) }}
          </nuxtLink>
        </p>
        <p class="font-semibold text-sm">
          <nuxtLink :to="emailToHref(companyData.email.main)">
            {{ companyData.email.main }}
          </nuxtLink>
        </p>
      </div>
      <div>
        <p>Siedziba firmy</p>
        <p class="font-semibold text-sm">
          {{ displayAddress }}
        </p>
      </div>
    </UContainer>
  </div>
</template>

<style scoped></style>
